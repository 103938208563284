@import '../../../../assets/css/variables';

nav {
    &.theme-top-header {
        min-height: $theme-header-height;
    }

    .navMenuBtn {
        .menuTrigger {
            width: 20px;
            height: 2px;

            &::after,
            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                background-color: inherit;
                right: 50%;
                transform: translateX(50%);
                transition: all .3s ease-in-out;
            }

            &::after {
                bottom: -8px;
            }

            &::before {
                top: -8px;
            }

            &.active {
                visibility: hidden;

                &::after,
                &::before {
                    visibility: visible;
                    width: 100%;
                    right: 0;
                }

                &::after {
                    transform: rotate(-45deg);
                    bottom: 0;
                }

                &::before {
                    transform: rotate(45deg);
                    top: 0;
                }

            }
        }

    }

    .notificationDropdown {
        .dropdown-toggle {
            .customBadge {
                line-height: 0.572;
                margin-left: -1.1rem;
            }

            &::after {
                display: none;
            }
        }

        .theme-notification-menu {
            ul {
                width: 280px;
                max-height: 306px;

                @include below-xsm() {
                    width: 220px;
                }

                @include maxheight400() {
                    max-height: calc(80vh - $theme-header-height);
                }
            }
        }
    }

    .profileDropdown {
        .dropdown-toggle {
            &::after {
                display: none;
            }
        }
    }
}