$enable-negative-margins: true;
$font-family-base: 'Source Sans Pro', sans-serif;
$body-color: #000;
$primary: #052865;
$secondary: #AFA46E;
$bg-body-tertiary: #F8F6F4;
$bg-body-secondary: #EBEBEB;
$body-secondary-color: #666666;
$body-tertiary-color: #333333;
$dark: #031637;
$danger: #DC3545;
$info: #3498db;
$warning: #DE8500;
$success: #198754;
$light: #E5F2FE;
$tooltip-max-width: 500px;
$card-spacer-x: 1.25rem;
$card-spacer-y: $card-spacer-x;
$card-img-overlay-padding: $card-spacer-x;
$link-color: #0D6EFD;
$light-text-emphasis: $link-color;
$btn-link-color: $secondary;
$btn-link-hover-color: rgba($secondary, .8);
$box-shadow-sm: 0 3px 10px rgba($body-color, .05);
$box-shadow: 0 0 4px rgba($body-color, .15);
$box-shadow-lg: 0 2px 4px rgba($body-color, .15);
$dropdown-font-size: 15px;
$dropdown-padding-y: .75rem;
$navbar-dark-color: var(--bs-white);
$nav-link-font-size: 15px;
$breadcrumb-font-size: 12px;
$breadcrumb-margin-bottom: 0;
$breadcrumb-item-padding-x: 10px;
$breadcrumb-divider-color: #999999;
$breadcrumb-active-color: #999999;
$breadcrumb-divider: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='5.246' height='8.371' viewBox='0 0 5.246 8.371'><path d='M-17367.951-12414.767l-1.062-1.061,3.125-3.125-3.125-3.125,1.063-1.061,4.184,4.186-.529.53Z' transform='translate(17369.014 12423.138)' fill='#999'/></svg>");
$h4-font-size: 22px;
$h5-font-size: 18px;
$btn-font-size: 14px;
$btn-font-weight: 600;
$btn-padding-x: 1.25rem;
$btn-padding-y: .5rem;
$btn-font-size-lg: 1rem;
$btn-border-radius: .25rem;
$btn-border-radius-lg: $btn-border-radius;
$btn-padding-y-lg: 0.75rem;
$dark-border-subtle: #999999;
$zindex-modal-backdrop: 1000;
$zindex-modal: 1000;
$modal-sm: 390px;
$modal-md: 600px;
$modal-lg: 760px;
$modal-xl: 1000px;
$modal-inner-padding: 1.25rem;
$modal-header-padding-y: .75rem;
$btn-close-color: $body-color;
$btn-close-width: 10px;
$btn-close-opacity: 1;
$btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$btn-close-color}' width='10' height='10' viewBox='0 0 10 10'><path d='M12.5,3.507,11.493,2.5,7.5,6.493,3.507,2.5,2.5,3.507,6.493,7.5,2.5,11.493,3.507,12.5,7.5,8.507,11.493,12.5,12.5,11.493,8.507,7.5Z' transform='translate(-2.5 -2.5)'/></svg>");
$tooltip-border-radius: .25rem;
$tooltip-opacity: 1;
$tooltip-bg: rgba($body-color, .8);
$border-color: #CFCFCF;
$success-bg-subtle: #D1E7DD;
$success-text-emphasis: $success;
$dark-text-emphasis: $bg-body-secondary;
$secondary-text-emphasis: #888888;
$primary-bg-subtle: #e6f0ff;
$secondary-border-subtle: #707070;
$badge-font-weight: 600;
$badge-padding-x: .75rem;
$info-bg-subtle: $light;
$info-text-emphasis: $primary;
$danger-text-emphasis: $danger;
$table-cell-padding-y: 0.25rem;
$table-cell-padding-x: 1rem;
$table-striped-bg: #F5F5F5;
$table-th-font-weight: 600;
$table-cell-vertical-align: middle;
$warning-bg-subtle: #FFF5CC;
$warning-text-emphasis: $warning;
$light-border-subtle: #DFDFDF;
$list-group-item-padding-y: 0.75rem;
$list-group-item-padding-x: .625rem;
$list-group-border-color: $light-border-subtle;
$list-group-hover-bg: $table-striped-bg;
$list-group-active-color: $body-color;
$list-group-active-bg: $light;
$list-group-active-border-color: $light-border-subtle;
$progress-height: 10px;
$progress-bg: #D9D7D7;
$progress-bar-bg: #00CB44;
$btn-link-disabled-color:#CFCFCF;

// Custom Variables
$theme-size-8: .5rem;
$theme-size-9: 0.5625rem;
$theme-size-10: 0.625rem;
$theme-size-11: 0.6875rem;
$theme-size-12: 0.75rem;
$theme-size-13: 0.8125rem;
$theme-size-14: 0.875rem;
$theme-size-15: 0.9375rem;
$theme-sidebar-width: 14.375rem;
$theme-sidebar-brand-height: 5em;
$theme-header-height: 4rem;
$theme-yellow: #FFCD00;
$theme-scroll-thumb-bg: #656464;
$theme-scroll-bg: #D5D3D3;
$theme-yellow-light: #E3D591;
$theme-light-brown-color: #CFA06C;
$theme-orange-color: #FF513E;
$theme-light-orange-color: #F67171;
$theme-light-blue-color: #298AFF;
$theme-blue-color: #0B33CB;
$theme-red-color: #FF0000;
$theme-light-green-color: #37C05A;
$theme-green-color: #08863A;
$theme-gray-color: #C9CACE;
$theme-red-light-color: #FB6767;

//Responsive Mixins
$below-xsm: 374px;
$below-sm: 575px;
$below-md: 767px;
$below-lg: 991px;
$below-xl: 1199px;
$below-1300: 1299px;
$below-1400: 1399px;
$below-1600: 1600px;
$below-1800: 1800px;

$maxheight600: 600px;
$maxheight400: 400px;

$above-sm: 576px;
$above-md: 768px;
$above-lg: 992px;
$above-xl: 1200px;
$above-1300: 1300px;

@mixin below-xsm {
  @media only screen and (max-width: $below-xsm) {
    @content;
  }
}

@mixin below-sm {
  @media only screen and (max-width: $below-sm) {
    @content;
  }
}

@mixin below-md {
  @media only screen and (max-width: $below-md) {
    @content;
  }
}

@mixin below-lg {
  @media only screen and (max-width: $below-lg) {
    @content;
  }
}

@mixin below-xl {
  @media only screen and (max-width: $below-xl) {
    @content;
  }
}

@mixin below-1300 {
  @media only screen and (max-width: $below-1300) {
    @content;
  }
}

@mixin below-1400 {
  @media only screen and (max-width: $below-1400) {
    @content;
  }
}

@mixin below-1600 {
  @media only screen and (max-width: $below-1600) {
    @content;
  }
}

@mixin below-1800 {
  @media only screen and (max-width: $below-1800) {
    @content;
  }
}

@mixin maxheight600 {
  @media only screen and (max-height: $maxheight600) {
    @content;
  }
}

@mixin maxheight400 {
  @media only screen and (max-height: $maxheight400) {
    @content;
  }
}

@mixin above-sm {
  @media only screen and (min-width: $above-sm) {
    @content;
  }
}

@mixin above-md {
  @media only screen and (min-width: $above-md) {
    @content;
  }
}

@mixin above-lg {
  @media only screen and (min-width: $above-lg) {
    @content;
  }
}

@mixin above-xl {
  @media only screen and (min-width: $above-xl) {
    @content;
  }
}

@mixin above-1300 {
  @media only screen and (min-width: $above-1300) {
    @content;
  }
}

//End of Responsive Mixins